import styled from 'styled-components'

const ExportFromArmeniaStyle = styled.div.attrs((props) => ({ className: props.className }))`
	display: flex;
	flex-direction: column;

	.no-result {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: var(--sp10x) auto 0;

		.imageCont {
			width: var(--sp18x);
			padding-top: var(--sp18x);
		}

		.title {
			margin: var(--sp5x) 0 var(--sp3x);
		}
	}


	.block {
		margin-top: var(--sp7x);
		position: relative;
		>.relativeWrapp{
			width:100%;
			margin: var(--sp10x) auto 0;
		}
		&.disable{
			.single-cat,.comp-title ,.single-sel-location,.ant-slider{
				opacity: 0.5;
				pointer-events: none;
			}
		}

        //* Filters and search
        .list-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {

            .list-head {
                flex-direction: column;
                align-items: flex-end;
            }
        }

        @media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
            .filter-in-export-cont {
                margin-top: var(--sp14x);
            }

            .list-head {

                .search-field-cont {
                    position: absolute;
                    top: calc(-1 * var(--sp9x));
                }
            }
        }
        //* End Filters and search
	}



	@media screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		.block {
			display: flex;
		}

	}


	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		.sort-by-cont{
			>.btn-action {
				position: absolute;
				top: 0;
                right: 0;
			}
		}
		.block {
			position: relative;
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.sort-by-cont{
			>.btn-action {
				width:100%;
			}
		}

		.block {
			>.relativeWrapp{
				margin: var(--sp5x) auto 0;
			}
		}
	}
`
export default ExportFromArmeniaStyle
