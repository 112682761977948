import React, { memo as Memo, useMemo, useState, useRef, useEffect, useCallback } from 'react'

//* HOC's
import { withDataContext, withUIContext, withLanguageContext } from 'context'
import { withRouter } from 'next/router'

//* Components
import { Page, Container, Text, AnimationWrapp, Image, Preloader } from 'components/common'
import { ExportCatalog, FilterInExport, ContactSection, Calculator } from 'components/pages'

//* Helpers
import { config, routerToPathAndQuery, arrayGenChild, arrayGen } from 'helpers'

//* Styles
import ExportFromArmeniaStyle from 'styles/pages/ExportFromArmeniaStyle'

const ExportFromArmenia = Memo(({ pages, products, pageParams, selectedLang, router, winWidth, loading, preloader, ...props }) => {
    //! Page Data
    const pageData = useMemo(() => pages?.[pageParams.name]?.[selectedLang], [pages, selectedLang, pageParams.name])
    const productsData = useMemo(() => products?.[pageParams?.queryName]?.[selectedLang], [products, selectedLang, pageParams.queryName])

    //! States
    const [secObj, setSecObj] = useState()
    const [secObjIndividual, setSecObjIndividual] = useState()

    //! Refs
    const TitleRef = useRef()
    const Ref1 = useRef()
    const Ref2 = useRef()
    const Ref3 = useRef()
    const Ref4 = useRef()

    //! Filter Change
    const onCategoryChange = useCallback(
        (cat, catId, multiple = true, clearKeyItems) => {
            const { pathname, query } = routerToPathAndQuery(router, cat, catId, multiple, clearKeyItems)

            multiple && delete query[pageParams.postType.queryParamKeys[2]] //? Page Param Removed on Category Change
            router.push({ pathname, query }, undefined, { scroll: true })
        },
        [router.query]
    )

    //! Animation
    useEffect(() => {
        if (
            pageData &&
            productsData &&
            !loading &&
            TitleRef?.current &&
            Ref1.current[1] &&
            Ref2?.current &&
            Ref3?.current?.[2] &&
            Ref4?.current?.[3] &&
            !preloader
        ) {
            setSecObjIndividual([Ref4.current[3].current[0].childNodes])

            winWidth >= 1024
                ? setSecObj([
                    Ref1.current[0].current,
                    Ref1.current[1].current,
                    arrayGenChild(TitleRef.current.childNodes[0].childNodes),
                    Ref2.current,
                    arrayGenChild(Ref3.current[0][0].childNodes),
                    Ref3.current[1].current,
                    Ref3.current[2].current,
                    arrayGenChild(Ref4.current[0].current.childNodes[0].childNodes),
                    Ref4.current[1].current.childNodes,
                    Ref4.current[2].current.childNodes,
                    arrayGen([[Ref4.current[3].current[1]], Ref4.current[3].current[2], Ref4.current[3].current[3]]),
                ])
                : setSecObj([
                    arrayGenChild(TitleRef.current.childNodes[0].childNodes),
                    arrayGenChild(Ref3.current[0][0].childNodes),
                    Ref3.current[1].current,
                    Ref3.current[2].current,
                    Ref2.current,
                    arrayGenChild(Ref4.current[0].current.childNodes[0].childNodes),
                    Ref4.current[1].current.childNodes,
                    Ref4.current[2].current.childNodes,
                    arrayGen([[Ref4.current[3].current[1]], Ref4.current[3].current[2], Ref4.current[3].current[3]]),
                ])
        }
    }, [TitleRef.current, Ref1.current, Ref2.current, Ref3.current, Ref4.current, pageData, productsData, loading, router, winWidth, preloader])

    const catalogue = useMemo(() => {
        if (loading) {
            return (
                <div className='relativeWrapp'>
                    <Preloader className={'preloaderProductProject'} />
                </div>
            )
        } else {
            return (
                <>
                    <ExportCatalog
                        ref={Ref2}
                        onPageChange={onCategoryChange}
                        sorts={pageData?.filters?.sorts}
                        catalogsArr={productsData?.products}
                        total={productsData?.total}
                        itemsCountPerPage={productsData?.perPageCount}
                        queryParamKeys={pageParams.postType.queryParamKeys}
                    >
                        <div className='no-result' ref={Ref2}>
                            <Image src='/images/noResult.svg' alt={props.translate('noResult')} />
                            <Text tag={'h5'} className={'title h5 MonRegular black'} text={'noResult'} />
                            <Text tag={'p'} className={'pM MonRegular ultraDarkGrey'} text={'noResultText'} />
                        </div>
                    </ExportCatalog>
                </>
            )
        }
    }, [productsData, loading])

    return (
        <ExportFromArmeniaStyle>
            <Page
                className='export-from-armenia'
                meta={{
                    title: pageData?.content?.meta?.title,
                    description: pageData?.content?.meta.description,
                    keywords: pageData?.content?.meta.keywords,
                    image: pageData?.content.meta_image,
                }}
            >
                {pageData && (
                    <AnimationWrapp state={secObj} formItems={secObjIndividual}>
                        <Container>
                            <Text tag={'stringToLetters'} className={'title h1 MonBold'} text={'exportProduct'} ref={TitleRef} />
                            <div className={`block ${loading ? 'disable' : ''}`}>
                                <FilterInExport
                                    onCategoryChange={onCategoryChange}
                                    categories={pageData.filters?.categories}
                                    types={pageData.filters?.subcategories}
                                    countries={pageData.filters?.countries}
                                    unitPrices={pageData.filters?.unitPrices}
                                    queryParamKeys={pageParams.postType.queryParamKeys}
                                    ref={Ref1}
                                />

                                {catalogue}
                            </div>
                        </Container>
                        <ContactSection
                            ref={Ref3}
                            title={pageData.content?.section_2_title}
                            text={pageData.content?.section_2_text}
                            url={config.routes.whatIsaProductSubmission.path}
                            linkText={'contactUsBtn'}
                            singlePage={true}
                        />
                        <Calculator
                            ref={Ref4}
                            calcData={pageData.potentialCalculator}
                            title={pageData.content.section_3_title}
                            rightText={pageData.content.section_3_right_text}
                            leftText={pageData.content.section_3_left_text}
                        />
                    </AnimationWrapp>
                )}
            </Page>
        </ExportFromArmeniaStyle>
    )
})

export default withRouter(
    withUIContext(withLanguageContext(withDataContext(ExportFromArmenia, ['products', 'pages']), ['selectedLang', 'translate']), [
        'winWidth',
        'loading',
    ])
)
